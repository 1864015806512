@import "./mixins";
@import "./footer";

#hero-section {
  position: relative;
  padding: 0;
  width: 100%;

  .image-container {
    width: 100%;
    position: relative;
    background: url(/assets/images/hero-image.jpg) no-repeat center center / cover;
    height: 600px;
    clip-path: polygon(0 0, 100% 0, 100% 79%, 50% 100%, 0 79%);

    .hero-text {
      position: relative;
      z-index: 1;
    }

    &::before {
      content: '';
      position: absolute;
      top: 0;
      left: 0;
      z-index: 0;
      width: 100%;
      height: 100%;
      background-color: rgba(0, 0, 0, 0.446);
    }
  }

  .arrow-down {
    position: absolute;
    bottom: -76px;
    left: calc(50% - 76px);
  }
}

.landing-page {
  background-color: var(--bs-gray-100);

  #desc {
    position: relative;

    .text-desc {
      p {
        width: 80%;
      }
    }

    .svg-watermark {
      position: absolute;
      left: 0;
      top: -5rem;
      z-index: 99;
    }
  }

  #marketing {
    position: relative;

    .clipped {
      padding: 5rem 5rem 12rem;
      clip-path: polygon(75% 100%, 100% 88%, 100% 0, 0% 0%, 0 83%);
      background-color: $primary;
    }

    .card-wrapper {
      padding-top: 5rem;

      .dark-card {
        width: 31%;
      }
    }

    .join-us {
      bottom: -10%;
      right: 20%;
      @include joinUs($secondary, #25282b);
    }
  }

  #organize {
    padding: 0;

    .wrapper {
      position: relative;

      video {
        height: 100%;
        width: 100%;
      }

      .text-content {
        position: absolute;
        bottom: 0;
        left: 0;
        width: 470px;
        background-color: #fff;
        padding: 3rem 0 0 3rem;
        border-radius: 0px 10px 0px 0px;
        border: none;

        .play-btn {
          svg {
            width: 100px;
            height: 100px;
          }
        }
      }
    }
  }

  #do-more {
    article {
      margin-bottom: 2rem;

      p {
        color: #52575c;
      }
    }
  }
}