#contest-modal {
  header {
    border-radius: 10px 10px 0 0;
  }

  .modal-content {
    border-radius: 10px;
    border: 0;
  }

  header {
    height: 64px;

    .close-btn {
      &:focus {
        box-shadow: none;
      }
    }
  }

  .btn-outline {
    border: 1px solid #52575c;
    color: #52575c;
  }

  .content {
    border-radius: 0 0 10px 10px;
    background: radial-gradient(
      153.32% 153.32% at 50% 50%,
      #f4fafa 0%,
      #b7e1db 100%
    );
    max-height: 92%;
    height: calc(100% - 64px);
  }

  &.leaderboard-modal {
    height: 90vh;
    max-height: 900px;

    .content {
      overflow: auto;
      @extend %scrollbar-styles;
    }

    .image-wrapper {
      width: 50%;
      padding-top: 2rem;
    }

    .leaderboard-card-wrapper {
      width: 80%;

      > * + * {
        margin-top: 1rem;
      }
    }
  }

  &.single-entry-modal {
    height: 90vh;
    max-height: 900px;

    .content {
      .image-wrapper {
        img {
          object-fit: cover;
          object-position: top;
          border-bottom-left-radius: 10px;
        }
      }

      .info {
        width: 50%;
        position: absolute;
        z-index: 9;
        bottom: 1rem;
        left: 1rem;
        display: flex;
        justify-content: space-between;
        align-items: center;

        .position {
          background-color: #f6f6f6;
          width: 130px;
          height: 130px;
          padding: 1rem;
          border: 1.5rem solid #fff;
          border-radius: 50%;
          display: flex;
          justify-content: center;
          align-items: center;
        }

        .name {
          margin-left: 2rem;
          background: linear-gradient(
            90.07deg,
            rgba(0, 0, 0, 0.5) -1.11%,
            rgba(0, 0, 0, 0) 99.95%
          );
          border-radius: 10px;
          padding: 0.7rem 1.5rem;
          flex-grow: 1;
        }
      }

      .side {
        background-color: #f4fafa;
        border-bottom-right-radius: 10px;
      }
    }
  }

  &.create-contest-modal {
    .image-wrapper {
      overflow: hidden;
      position: relative;

      width: 100px;
      height: 108px;
      border-radius: 10px;
      font-size: 10px;
      background-color: #cacccf;
      cursor: pointer;
      transition: all 0.1s ease-in-out;

      label {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
      }

      img {
        border-radius: 5px;
        object-fit: cover;
        object-position: top;
      }

      &:hover {
        background-color: #d7d8db;
      }

      &:active {
        background-color: #25282bcd;
        color: rgba(255, 255, 255, 0.768);

        span {
          color: rgba(255, 255, 255, 0.768) !important;
        }
      }
    }

    .content {
      background: unset;
      background-color: #f6f6f6;

      > section + section {
        margin-top: 1rem;
      }

      section {
        background-color: #fff;
        box-shadow: 0px 0px 10px rgba(138, 149, 158, 0.1);
        border-radius: 10px;
        border: 0.5px solid #cacccf;
        padding: 1rem 1.2rem;

        > div ~ div {
          margin-top: 1.5rem;
        }

        div {
          .contest-form-control::placeholder,
          input::placeholder,
          textarea::placeholder,
          .desc {
            color: #00000066;
            font-weight: 500;
          }

          .desc {
            font-size: 0.875rem;
          }

          label {
            cursor: pointer;
          }

          input[type="text"],
          input[type="email"],
          input[type="number"],
          textarea,
          .contest-form-control,
          .invite-display {
            border: 0.5px solid #cacccf;
            border-radius: 5px;
            background-color: transparent;
            transition: 0.3s all ease-in-out;

            &:focus,
            &:hover {
              border-color: #cacccf;
              outline: none;
            }
          }

          label.contest-photo {
            width: 100px;
            height: 108px;
            border-radius: 10px;
            font-size: 10px;
            background-color: #cacccf;
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: center;
            cursor: pointer;
            transition: all 0.1s ease-in-out;

            &:hover {
              background-color: #d7d8db;
            }

            &:active {
              background-color: #25282bcd;
              color: rgba(255, 255, 255, 0.768);

              span {
                color: rgba(255, 255, 255, 0.768) !important;
              }
            }
          }
        }

        .accordion-body {
          > div ~ div {
            margin-top: 1.5rem;
          }

          .entry-form {
            button {
              border-radius: 20px;
              width: 11em;
              height: 2em;
              display: inline-flex;
              align-items: center;
              justify-content: center;

              &.btn-grey {
                background-color: #d6d6d6;
              }
            }
          }

          .info {
            p {
              color: #3892ef;
            }
          }

          .invite-display {
            display: flex;
            justify-content: flex-start;
            flex-wrap: wrap;
            align-items: flex-start;

            .invite {
              font-size: 12px;
              font-weight: 400;
              color: #000000a3;
              background-color: #f5f5f5;
              padding: 0.3em 1em;
              margin: 0.2rem 0.3rem;
            }
          }

          .accordion-header {
            button {
              background-color: #d6d6d6 !important;
              border-radius: 20px;
              padding: 0.5em 1.7em;
            }
          }

          #entryfee,
          #votingFee {
            div {
              > div {
                > * {
                  height: 54px;
                }

                select,
                input {
                  border-color: rgba(0, 0, 0, 0.36);
                  font-size: 18px;
                  font-weight: 400;
                  color: rgba(0, 0, 0, 0.8);
                }

                select {
                  text-transform: uppercase;

                  option {
                    font-size: 14px;
                  }
                }

                input {
                  width: 184px;
                }
              }
            }
          }
        }

        .accordion-button {
          background-color: transparent !important;
          box-shadow: none;

          &:focus,
          &:active {
            outline: none;
            border: none;
          }
        }
      }

      .accordion-cta {
        padding: 0.9rem 1.4rem;
        border-radius: 5px;
        font-size: 14px;
        font-weight: 600;
      }
    }
  }

  &.entry-form-modal {
    height: 90vh;
    max-height: 900px;

    .content {
      overflow: auto;
      @extend %scrollbar-styles;
      background-color: #f9f9fa;
      background: #f9f9fa;

      &.starter-template {
        .template {
          height: 228px;
          width: 23%;
          border-radius: 10px;
          background-color: #fff;
          transition: all 0.3s ease-in-out;
          cursor: pointer;

          img {
            height: 160px;
            border-radius: 10px 10px 0 0;
            width: 100%;
            object-fit: cover;
          }

          &:hover {
            transform: scale(1.01);
            filter: drop-shadow(0px 4px 40px rgba(138, 149, 147, 0.4));
          }
        }
      }

      &.blank-entry-form {
        section {
          border: 0.5px solid #cacccf;
          border-radius: 5px;
          box-shadow: 0px 0px 10px rgba(138, 149, 158, 0.1);
          background-color: #fff;
          margin: 1rem 0;
          padding: 2rem 1rem;
        }

        .header {
          background-color: #eafaf2;
          border-top: 10px solid #27a064;
          height: 144px;
          padding: 1rem 2rem;

          input {
            border: none;
            background-color: inherit;

            &:focus {
              outline: none;
              border: none;
            }
          }
        }

        section {
          &.green-border {
            border-left: 10px solid #30c57b;
          }

          label.profile-photo {
            width: 104px;
            height: 104px;
            background-color: #cacccf;
            cursor: pointer;

            img {
              object-fit: cover;
              border-radius: 10px;
            }
          }

          input.border-input {
            border: none;
            border-bottom: 0.5px solid #30c57b;
            padding-bottom: 0.5rem;

            &:focus {
              outline: none;
              border: none;
              border-bottom: 0.5px solid #30c57b;
            }
          }

          .options {
            > li + li {
              padding-top: 1rem;
            }

            input::placeholder {
              color: #cacccf;
            }

            &.file-selection {
              li {
                flex-basis: 33%;
                padding: 0.3rem 0;
              }
            }
          }

          button.accordion-button {
            border: none !important;
            outline: none !important;
            box-shadow: none !important;
            background-color: transparent !important;
          }

          .question-selection {
            min-width: 220px;

            > button {
              font-size: 12px;
              width: 100%;
              display: flex;
              align-items: center;
              justify-content: space-between;
              padding: 0.6rem 1rem;
            }

            .dropdown-menu {
              width: 230px;
              box-shadow: 1px 1px 10px rgba(0, 0, 2, 0.3);
              border-radius: 10px;

              li + li {
                margin-top: 0.5rem;
              }

              li {
                font-size: 12px;
                font-weight: 500;
                color: #52575c;
                padding: 0.5rem 1.5rem;
              }

              hr {
                border: 0.5px solid #cacccf;
              }
            }
          }
        }

        .cta-buttons {
          button {
            padding: 0.8rem 1.4rem;

            &.add-new {
              &:focus {
                outline: none;
                box-shadow: none;
              }
            }

            &.preview {
              border: 1px solid rgba(0, 0, 0, 0.4);
            }

            &.submit-btn {
              background-color: rgba(0, 0, 0, 0.3);
            }
          }
        }
      }
    }
  }
}

/* Single Entry Modal */
.single-entry-modal {
  margin-top: 5vh;
  width: 90%;

  .modal-header {
    height: 64px;
  }

  .content {
    .image-wrapper {
      height: 900px;

      img {
        object-fit: cover;
      }
    }
  }
}

#confirm-modal {

  .confirm-modal-content {
    background-color: transparent;
    border: 0;
  }

  .confirm-modal-header  {
    .close-btn {
      &:hover {
        .close-shadow {
          opacity: 0.4;
        }
      }
    }
  }

  .confirm-modal-body {
    border-radius: 5px;

    .desc {
      width: 80%;
    }

    .btn-wrapper {
      max-width: 75%;

      .cancel-btn {
        background-color: rgba(0, 0, 0, 0.3);
  
        &:hover {
          background-color: rgba(0, 0, 0, 0.35);
        }
      }
    }

    
  }

  @media (min-width: 992px) {
    max-width: 500px;
  }
}


#createContestRegistration {
  &.form-content {
    #form-header {
      background-color: #EAFAF2;
      border: 0.5px solid #CACCCF;
      box-shadow: 0px 0px 10px rgba(138, 149, 158, 0.1);
      border-radius: 8px;
      border-top: 10px solid #27A064;
      padding: 1.5rem 2.5rem;

      .input-field {
        border: 0;
        background-color: transparent;
        width: 90%;

        &:focus,
        &:active {
          outline: none;
        }
      }

      .form-desc {
        resize: none;
      }
    }

    #form-questions {
      .question-card {
        border: 0.5px solid #CACCCF;
        box-sizing: border-box;
        box-shadow: 0px 0px 10px rgba(138, 149, 158, 0.1);
        border-radius: 8px;
        padding: 1rem;
        margin: 1rem 0;

        &#static-question {
          .user-photo {
            width: 100px;
            height: 100px;
            border-radius: 10px;
          }
        }

        .question-wrapper {
          .required-marker {
            top: 1rem;
            font-size: 1.1rem;
          }

          .question-label {
            border: none;
            padding: .5rem 1rem;
            width: 90%;
            color: #25282B;
            border-color: transparent;
            background-color: transparent;
     
            &:focus {
              outline: none;
            }
          }

          .question-option {
            padding: .5rem 1rem;

            .delete-btn {
              display: none;
            }

            .question-type-marker {
              cursor: pointer;
            }
          }

          .toggle-btns {
            button {
              color: #E5E5E5;
            }
          }

          .question-type-dropdown {
            .dropdown-toggle {
              background-color: transparent;
              border: 1px solid #CACCCF;
              border-radius: 4px;
              text-align: start;

              &::after {
                position: absolute;
                top: 1rem;
                right: 1rem;
              }
            }
          }
        }

        #question-footer {
          #delete-btn {
            border-right: 1px solid #CACCCF;
            border-radius: 0;
          }
        }

        &.opened {
          border-left: 10px solid #30C57B;

          .question-wrapper {
            .question-label {
              border-bottom: 0.5px solid #30C57B;
              background-color: #EAFAF2;
            }
          }
        }

        &.closed {
          cursor: pointer ;
  
          & * {
            cursor: pointer ;
          }
        }

      }
    }

    .question-field {
      border: 0;
      border-bottom: 0.5px solid #30C57B;
      padding: .5rem 1rem;
      background-color: transparent;
      width: 100%;

      &::placeholder {
        color: #52575cab;
        font-size: 14px;
        font-weight: 300;
      }

      &:focus,
      &:active {
        outline: none;
      }
    }

    .question-dropdown {
      max-width: 300px;
    }

    .add-btn {
      border: 0.5px solid #CACCCF;
      background-color: #fff;
      box-shadow: 0px 0px 10px rgba(138, 149, 158, 0.1);
      border-radius: 8px;
      padding: 1.5rem;
    }

    .mode-btn {
      border: 1px solid rgba(0, 0, 0, 0.4);
      color: rgba(0, 0, 0, 0.4);
    }
  }

  &.edit-mode {
    #form-content {
      #form-header {
        .form-desc {
          resize: vertical;
        }
      }

      #form-questions {
        .question-card {
          .question-wrapper {
            .question-option {
              .delete-btn {
                display: none;
              }
  
              .question-type-marker {
                display: inline-block;
              }
  
              &:hover {
                .delete-btn {
                  display: inline-block;
                }
  
                .question-type-marker {
                  display: none;
                }
              }
            }
          }
        }
      }
    }
  }
}

.question-type-dropdown-menu {
  z-index: 1055;
  margin-top: 1rem;

  .dropdown-item {
    &:active {
      background-color: #e9ecef;
    }
  }
}

