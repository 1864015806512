@import "~bootstrap/scss/functions";
@import "~bootstrap/scss/variables";
@import "~bootstrap/scss/utilities";
@import "~bootstrap/scss/mixins";

$primary: #144499;
$secondary: #30c57b;
$dark: #25282b;
$red: #ff4848;
$light: #fff;
$leader-orange: #ff9f00;
$leader-blue: #3dc6fc;
$leader-green: #4cd6b6;
$platinum: #D4AF37;
$platinum-darker: #A57C00;

$custom-colors: (
  "black60": lighten(#000, 60%),
  "fbBlue": #3b5999,
  "primary-shade": #0f3271,
  "secondary-shade": #27a064,
);

$smallScreen: "max-width: 576px";
$xtraSmallScreen: "max-width: 340px";

$theme-colors: map-merge($theme-colors, $custom-colors);
