.profile-dropdown {
  list-style-type: none;

  .user-profile {
    background-color: #f2f6fd;
    border-radius: 10px 10px 0 0;

    .name {
      font-size: 1rem;
      font-weight: 600;
    }

    .email {
      font-size: 0.6rem;
      font-weight: 400;
    }

    .user-avatar {
      img {
        width: 64px;
        height: 64px;
        object-fit: cover;
      }
    }
  }

  hr {
    border-color: #e5e5e5;
    opacity: 0.3;
  }

  a {
    font-size: 1rem;
    font-weight: 500;
    color: #52575c;
    text-transform: capitalize;
    padding: 0;
    display: flex;
    align-items: center;

    svg {
      margin-right: 0.625rem;
    }
  }
}

nav.navbar {
  padding: 1rem 0.625rem;

  .user-avatar {
    height: 40px;
    width: 40px;
    border-radius: 50%;

    img {
      object-fit: cover;
      object-position: top;
      border-radius: 50%;
    }
  }

  .header-cta {
    display: none;
  }

  .navbar-nav {
    .create-contest {
      background-color: $secondary;
      color: #fff;
      border-radius: 5px;
    }

    >li {
      >a {
        font-size: 0.875rem;
        color: #fff !important;
      }

      &.search-wrapper {
        .input-group {
          border-radius: 5px;
        }

        input[type="search"] {
          border: none;
          outline: none;

          &::placeholder {
            color: #8282827b;
          }
        }
      }

      .icon-wrapper {
        position: relative;

        .new-notification {
          position: absolute;
          top: -5px;
          right: -3px;
        }
      }

      &.dropdown {
        .dropdown-toggle {
          &::after {
            display: none;
          }
        }

        .dropdown-menu {
          border-radius: 10px;
        }

        .toggle-btn {
          margin-left: 0.625rem;

          &:focus,
          &:active {
            outline: none;
            border: none;
            box-shadow: none;
          }
        }


        .notification-dropdown {
          width: 500px;
          left: -700%;

          li {
            &+* {
              margin-top: 1.25rem;
            }

            .image-wrapper {
              width: 35px;
              height: 35px;
              border-radius: 50%;

              img {
                object-fit: cover;
                object-position: top;
                border-radius: 50%;
              }
            }

            .notification-details {
              .desc {
                font-weight: 400;

                span {
                  font-weight: 500;
                }
              }

              .time {
                color: rgba(0, 0, 0, 0.446);
                margin: 0.625rem 0;
              }

              .btn-wrapper {
                button {
                  padding: 0.4rem 1.25rem;
                  font-size: 0.875rem;
                  font-weight: 500;
                  border-radius: 5px;

                  &.btn-success {
                    background-color: #30c57b !important;
                    border: none;
                  }
                }
              }
            }
          }

          .view-more {
            padding: 0.8rem 1.25rem;
            background-color: $primary;
            color: #fff;
            font-weight: 300;
            border-radius: 5px;
          }
        }
      }
    }

    a.active {
      border-bottom: 2px solid $secondary;
    }

    >* {
      margin: 0 1rem;
    }
  }
}

@media ($smallScreen) {
  .header-cta {
    display: block !important;
  }
}
