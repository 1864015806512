.price-switcher {
  border: 1px solid #e5e5e5e5;
  border-radius: 50px;
  background-color: #fff;
  width: fit-content;
  margin-right: auto;
  margin-left: auto;

  button {
    outline: none;
    border: none;
    background-color: transparent;
    padding: 16px 42px;
    border-radius: 50px;
    color: #52575c;
    font-size: 14px;
    transition: background-color 0.1s ease-in;

    &.selected {
      background-color: $primary;
      color: #fff;
      transition: background-color 0.5s ease-out;
      font-weight: 700;
    }
  }
}

.price-card {
  border-radius: 10px;
  background-color: #fff;
  position: relative;
  padding-top: 40px;
  box-shadow: 0px 10px 50px rgb(0 0 0 / 25%);
  min-height: 600px;

  .flag {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    display: flex;
    justify-content: center;

    &>span {
      width: 80%;
      height: 6px;
      border-radius: 0px 0px 20px 20px;
      background-color: $primary;
    }

    &.premium {
      &>span {
        background-color: #fff;
      }
    }

    &.vip {
      &>span {
        background-color: $platinum;
      }
    }
  }

  &-body {
    sup {
      vertical-align: super;
    }

    &.basic {
      .rate {
        color: #6c757d;
      }
    }

    .original-price {
      bottom: -0.7rem;
      left: 0;
      right: 0;
    }
  }

  &-cta {
    padding: 16px 48px;
    text-transform: uppercase;

    &.vip-cta {
      border: 1px solid $platinum-darker;
      background-color: transparent;
      color: $platinum-darker;

      &:hover {
        background-color: $platinum-darker;
        color: #fff;
      }

      &.active-plan {
        background-color: $platinum-darker;
        color: #fff !important;

        &:hover,
        &:focus {
          background-color: lighten($platinum-darker, 2%);
        }
      }
    }
  }

  &-footer {
    padding: 21px 24px;
    text-align: start;

    ul {
      li {
        margin-left: 16px;
        position: relative;
        color: #52575c;
        font-size: 12px;

        &::before {
          content: "";
          height: 4px;
          width: 4px;
          background-color: #52575c;
          border-radius: 50%;
          position: absolute;
          top: 6px;
          left: -16px;
        }

        &:not(:last-of-type) {
          margin-bottom: 16px;
        }
      }
    }

    &.basic,
    &.free {
      // background-color: #f2f6fd;
      border-top: 1px solid $primary;
      height: 100%;
      border-radius: 0 0 10px 10px;
    }

    &.premium {
      ul {
        margin: 0;
        padding: 0;

        li {
          color: #fff;

          &::before {
            background-color: #fff;
          }
        }
      }
    }

    &.vip {
      ul {
        li {
          color: $platinum;

          &::before {
            background-color: $platinum;
          }
        }
      }
    }
  }

  &.premium {
    background: url(/assets/images/premium-bg.png),
      linear-gradient(160.19deg, #00eb76 1.29%, #056133 79.03%);
    background-repeat: no-repeat;
    background-size: cover;

    & * {
      color: #fff;
    }
  }

  &.vip {
    background: url(/assets/images/platinum-bg.png),
      linear-gradient(160.19deg, #333333 1.29%, #1a1a1a 79.03%);
    background-repeat: no-repeat;
    background-size: cover;

    & * {
      color: $platinum;
    }
  }
}