#loader {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: var(--bs-primary);
  text-align: center;

  .spinner {
    width: 100px;
    height: 50px;
    position: absolute;
    top: 0;
    bottom: 0;
    right: 0;
    left: 0;
    margin: auto;
    text-align: center;
  }

  .spinner .ball {
    width: 20px;
    height: 20px;
    background-color: #fff;
    border-radius: 50%;
    display: inline-block;
    -webkit-animation: motion 3s cubic-bezier(0.77, 0, 0.175, 1) infinite;
    animation: motion 3s cubic-bezier(0.77, 0, 0.175, 1) infinite;
  }

  p {
    color: #fff;
    margin-top: 5px;
    font-family: sans-serif;
    letter-spacing: 3px;
    font-size: 10px;
  }

  @-webkit-keyframes motion {
    0% {
      transform: translateX(0) scale(1);
    }

    25% {
      transform: translateX(-50px) scale(0.3);
    }

    50% {
      transform: translateX(0) scale(1);
    }

    75% {
      transform: translateX(50px) scale(0.3);
    }

    100% {
      transform: translateX(0) scale(1);
    }
  }

  @keyframes motion {
    0% {
      transform: translateX(0) scale(1);
    }

    25% {
      transform: translateX(-50px) scale(0.3);
    }

    50% {
      transform: translateX(0) scale(1);
    }

    75% {
      transform: translateX(50px) scale(0.3);
    }

    100% {
      transform: translateX(0) scale(1);
    }
  }
}