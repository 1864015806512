@mixin joinUs($background, $button-color) {
  background-color: $background;
  box-shadow: 0px 20px 50px rgb(29 53 71 / 10%);
  border-radius: 15px;
  padding: 1.875rem;
  position: absolute;
  z-index: 1;
  left: calc(50% - 295px);
  top: -18%;

  h5 {
    font-size: 1.5rem;
    font-weight: 700;
    line-height: 1.25em;
  }

  p {
    font-size: 1rem;
    font-weight: 400;
    line-height: 1.3em;
    color: #000;
  }

  form {
    margin: 1rem 0;

    input {
      padding: 1em 2.5em 1em 1em;
      font-size: 1rem;
      border-radius: 5px;
      border: none;
      flex-grow: 1;

      &::placeholder {
        color: rgba(0, 0, 0, 0.3);
      }

      &:focus,
      &:active {
        outline: none;
        border: none;
      }
    }

    button {
      padding: 1em 2.5em;
      color: #fff;
      font-size: 1rem;
      font-weight: 600;
      border-radius: 5px;
      background-color: $button-color;
    }
  }
}

%scrollbar-styles {
  &::-webkit-scrollbar-thumb {
    background: rgba(0, 0, 0, 0.3);
    border-radius: 10px;
  }

  &::-webkit-scrollbar-track {
    box-shadow: inset 0 0 5px #fff;
    border-radius: 3px;
  }

  &::-webkit-scrollbar {
    width: 5px;
    height: 5px;
  }
}