.auth-page {
  background-color: $primary;
  min-height: 100vh;
  background-image: url(/assets/images/bg-vector.svg);
  background-repeat: no-repeat;
  background-size: cover;

  main {
    background-color: transparent;
  }

  #verify-account-form {
    input {
      margin: 0;
      text-align: center;
      line-height: 80px;
      font-size: 48px;
      border-top: 1px solid #ccc;
      border-left: 1px solid #ccc;
      border-bottom: 1px solid #ccc;
      border-right: none;
      outline: none;
      width: 20%;
      transition: all .2s ease-in-out;

      &:focus {
        border-color: var(--bs-dark);
        border-right: 1px solid var(--bs-dark);
      }

      &::selection {
        background: transparent;
      }

      &:last-of-type {
        border-right: 1px solid #ccc;
      }
    }
  }

  main {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    min-height: 80vh;
  }

  .bg-vector {
    >* {
      position: fixed;
      z-index: -1;
    }

    &-1 {
      bottom: 0;
      left: 0;
    }

    &-2 {
      bottom: 0;
      right: 1rem;

      @media ($smallScreen) {
        right: -5rem;
      }
    }

    &-3 {
      top: 0;
      right: 0;
    }
  }

  header {
    padding: 3rem 10rem;

    @media ($smallScreen) {
      padding: 3rem;
    }
  }

  .form-wrapper {
    padding: 3rem;
    min-width: 500px;
    border-radius: 10px;

    %shared-pseudo-style {
      content: "";
      position: absolute;
      border-top: 0.7px solid rgba(0, 0, 0, 0.6);
      width: 45%;
    }

    .divider {
      position: relative;

      &::before {
        @extend %shared-pseudo-style;
        left: 0;
      }

      &::after {
        @extend %shared-pseudo-style;
        right: 0;
      }
    }

    button.btn {
      width: 100%;
      margin: 1rem;

      &.btn-outline-primary {
        border-color: #3b5999 !important;
        color: #3b5999 !important;
      }

      &.btn-outline-primary,
      &.btn-outline-danger {
        &:hover {
          color: #fff !important;
        }
      }

      p {
        color: inherit;
      }
    }

    @media ($smallScreen) {
      min-width: unset;
      padding: 1rem;
    }

    @media ($xtraSmallScreen) {
      width: 280px;
      min-width: unset;
    }
  }
}