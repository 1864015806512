$theme-colors: (
  "primary": #144499,
  "secondary": #30c57b,
  "dark": #25282b,
  "danger": #ff4848,
  "warning": #fbc408,
  "light": #fff,
);

$input-btn-focus-box-shadow: none;
$btn-focus-width: 0;

@import "~bootstrap/scss/bootstrap.scss";
