main {
  &.has-filter {
    display: flex;

    .list-contests,
    .grid-contests {
      width: 75%;
    }

    #filter-wrapper {
      width: 25%;
    }
  }
}

#contest-table {
  thead {
    tr {
      th {
        padding: 0 1rem 1rem;
      }
    }
  }

  tbody {
    color: #52575c;

    tr {
      th {
        padding: 1rem;

        .image-wrapper {
          width: 100px;
          height: 100px;
          overflow: hidden;
          border-radius: 5px;

          img {
            object-fit: cover;
            border-radius: 5px;
          }
        }

        .count {
          color: #00000066;
        }
      }

      td {
        vertical-align: middle;
        padding: 1rem;

        .price {
          background-color: rgba(229, 242, 255, 0.6);
          border-radius: 10px;
        }
      }
    }
  }
}

#filter-wrapper {
  max-width: 260px;
  width: 260px;

  h3 {
    letter-spacing: 1px;
  }

  hr {
    border: 1px solid #e5e5e5;
  }

  .option {
    >* {
      cursor: pointer;
    }

    .custom-radio-btn {
      width: 20px;
      height: 20px;
      display: inline-flex;
      align-items: center;
      justify-content: center;
      border: 1px solid #52575c;
      border-radius: 50%;
      padding: 0.5px;

      .circle {
        width: 10px;
        height: 10px;
        display: inline-block;
        margin: 2.5px;
        border-radius: 50%;
        background-color: #fff;
      }
    }

    input[type="radio"] {
      display: none;
    }

    input:checked {
      ~.custom-radio-btn {
        border-color: $secondary;

        .circle {
          background-color: $secondary;
        }
      }
    }
  }

  .price-range {
    .slider {
      -webkit-appearance: none;
      width: 100%;
      height: 4px;
      background: #c4c4c4;
      outline: none;
      opacity: 0.7;
      -webkit-transition: 0.2s;
      transition: opacity 0.2s;

      &:hover {
        opacity: 1;
      }

      &::-webkit-slider-thumb {
        -webkit-appearance: none;
        appearance: none;
        width: 10px;
        height: 10px;
        border-radius: 50%;
        background: #30c57b;
        cursor: pointer;
      }

      &::-moz-range-thumb {
        width: 10px;
        height: 10px;
        border-radius: 50%;
        background: #04aa6d;
        cursor: pointer;
      }

      &::-moz-range-progress {
        height: 10px;
        background: #04aa6d;
      }

      &::-ms-fill-lower {
        height: 10px;
        background: #04aa6d;
      }
    }

    .controls {
      input[type="number"] {
        width: 100px;
        border-radius: 5px;
        border: 1px solid #dbdde0;
        padding: 0.4rem 0.5rem;
        font-size: 14px;
        font-weight: 400;
        color: #52575c;
      }
    }
  }
}

.layout-switcher {
  position: fixed;
  z-index: 9999;
  right: 8px;
  bottom: 8px;
}