// text utility classes
.fs-hero-title {
  font-size: 2.5rem;
  line-height: 1.25em;
  font-weight: bold;
}

.fs-section-title {
  font-size: 2rem;
  line-height: 1.3em;
}

.fs-section-subtitle {
  font-size: 1.2rem !important;
  line-height: 1.2em !important;
}

.fs-card-title {
  font-size: 1.25rem;
  line-height: 1.2em;
}

.fs-card-subtitle {
  font-size: 1.125rem;
  line-height: 1.2em;
}

.fs-small {
  font-size: 0.875rem;
  line-height: 1.6em;
}

.fs-label {
  font-size: 0.75rem;
  line-height: 2em;
}

.fw-medium {
  font-weight: 500 !important;
}

.fw-semi-bold {
  font-weight: 600 !important;
}

.text-grey {
  color: #52575c !important;
}

.text-grey-50 {
  color: #52575cab !important;
}

.bg-grey {
  background-color: #52575c;
}

.body-tint {
  background-color: #f9f9fa;
}

.bg-leader-orange {
  background-color: $leader-orange;
}

.bg-leader-green {
  background-color: $leader-green;
}

.bg-leader-blue {
  background-color: $leader-blue;
}

.br-32 {
  border-radius: 32px;
}

.br-10 {
  border-radius: 10px;
}

.br-8 {
  border-radius: 8px;
}

// button utility classes
.cta-btn {
  border-radius: 5px;
  padding: 0.7em 2.5em;
}

.btn-lighter {
  background-color: rgba(255, 255, 255, 0.9);
  color: #9a9797;
}

.btn-grey {
  background-color: rgba(0, 0, 0, 0.3);
  color: #fff;

  &:hover {
    background-color: rgba(0, 0, 0, 0.4);
    color: #fff;
  }

  &.outline {
    border: 1px solid rgba(0, 0, 0, 0.3);
    color: rgba(0, 0, 0, 0.3);

    &:hover {
      background-color: rgba(0, 0, 0, 0.3);
      color: #fff;
    }
  }
}

.no-border {
  outline: none;
  border: none;
  box-shadow: none;

  &:focus,
  &:active {
    outline: none;
    border: none;
    box-shadow: none;
  }
}

.shadow-sm-md {
  @media screen and (min-width: 768px) {
    box-shadow: 0 0.125rem 0.25rem rgb(0 0 0 / 8%);
  }
}

.link-container {
  padding: 0.75rem 1.5rem;
  border: 0.7px solid rgba(196, 196, 196, 0.5);
  border-radius: 5px;

  &:active,
  &:focus {
    outline: none;
  }

  &::placeholder {
    color: #c4c4c4;
    font-size: 0.8rem;
  }
}

.copy-btn {
  min-width: 60px;
}

.btn-upgrade {
  padding: 12px 16px;
  background-color: #f6f6f6;
  border: 1px solid #cacccf;
  border-radius: 8px;

  &:hover,
  &:active,
  &:focus {
    color: #196e44;
  }
}

// card utility classes
.dark-card {
  background-color: #0f3271;
  border-radius: 10px;
  padding: 1.8rem;
}

.contest-card {
  background-color: #fff;
  border-radius: 24px;
  padding: 0;
  cursor: pointer;
  transition: all ease 0.3s;

  &:hover {
    box-shadow: 0 0.5rem 1rem rgb(0 0 0 / 15%) !important;
  }

  .fs-card-title {
    height: 48px;
  }

  .img-content {
    position: relative;
    border-radius: 1.25rem 1.25rem 0 1.25rem;

    img {
      object-fit: cover;
      object-position: top;
      border-radius: 1.25rem 1.25rem 0 1.25rem;
      background-color: #9a9797;
    }

    .desc {
      position: absolute;
      top: 5px;
      left: 5px;
      right: 5px;
      padding: 0.625rem;
      font-size: 0.75rem;

      %shared-padding {
        padding: 0.1rem 0.3rem;
      }

      .rate {
        background-color: rgba(255, 255, 255, 0.5);
        color: #52575c;
        @extend %shared-padding;
      }
    }
  }

  .text-content {
    padding: 1.25rem 1.875rem;
    padding-top: 0;

    .image-wrapper {
      width: 40px;
      height: 40px;
      border-radius: 50%;
      overflow: hidden;

      img {
        object-fit: cover;
        object-position: center;
      }
    }

    .count {
      margin: 0.625rem 0;

      span {
        font-size: 1rem;
        font-weight: 500;
        color: #52575c;
      }
    }

    button {
      font-weight: 600;
      font-size: 0.875rem;
      border-radius: 7px;
      padding: 0.625rem;
    }
  }
}

.countdown-timer {
  background-color: rgba(255, 255, 255, 0.9);
  font-size: 0.75rem;
  font-weight: 300;
  display: flex;

  .prefix {
    background-color: $secondary;
    padding: 0.1rem 0.3rem;
    color: #fff;
    height: 100%;
    width: fit-content;
  }

  .time {
    padding: 0.1rem 0.3rem;
    border-top-left-radius: 0 !important;
    border-bottom-left-radius: 0 !important;
  }
}

.vote-overlay-card {
  height: 260px;
  position: relative;
  border-radius: 9px;
  cursor: pointer;
  transition: all ease-in 0.3s;
  // width: 24%;

  .overlay {
    position: relative;
    width: 100%;
    left: 0;
    height: 100%;
    top: 0;
    transition: all 0.3s ease-in-out 0.4s;

    img {
      object-fit: cover;
      object-position: top;
      border-radius: 9px;
    }

    &-background {
      display: block;
      border-radius: 9px;
      position: absolute;
      left: 0;
      top: 0;
      width: 100%;
      height: 100%;
      background: linear-gradient(180deg, rgba(0, 0, 0, 0) 30.8%, rgba(0, 0, 0, 0.4) 75.72%);
    }
  }

  &:hover {
    .overlay {
      &-background {
        background: linear-gradient(180deg, rgba(0, 0, 0, 0) 30.8%, rgba(0, 0, 0, 0.878) 75.72%);
      }
    }
  }

  .text-content {
    padding: 1.25rem 1.875rem;
    position: absolute;
    color: #fff;
    bottom: 0;
    left: 0.75rem;
    width: calc(100% - 1.5rem);
    border-radius: 0 0 9px 9px;

    h4 {
      width: 80%;
    }

    .count {
      margin: 0.625rem 0;

      span {
        font-size: 1rem;
        font-weight: 500;

        button {
          &.vote {
            background-color: #f5f5f53d;
            color: #ffffffcc;
          }

          &.voted {
            background-color: #ffffff;
            color: #00000099;
          }
        }
      }
    }

    .image-wrapper {
      width: 40px;
      height: 40px;
      border-radius: 50%;
      overflow: hidden;

      img {
        object-fit: cover;
        object-position: center;
      }
    }
  }
}

.leaderboard-card {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0.5rem 1.8rem;
  border-radius: 5px;
  margin: 0.3rem 0;
  cursor: pointer;

  .leaderboard-image {
    width: 32px;
    height: 32px;
    object-fit: cover;
    border-radius: 50%;
  }
}

.jumbotron {
  background-color: #fff;

  .profile {
    background-image: url("/assets/images/bg-image.png");
    background-position: center;
    background-size: cover;
    background-repeat: no-repeat;
    min-height: 190px;
    position: relative;

    & * {
      z-index: 1;
    }

    &::before {
      content: "";
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      background: rgba(255, 255, 255, 0.8);
      z-index: 0;
    }

    .image-wrapper {
      height: 120px;
      width: 120px;
      border-radius: 5px;
      overflow: hidden;
      background-color: #fff;
      position: relative;

      img {
        border-radius: 5px;
        object-fit: cover;
        object-position: top;
      }

      .edit-btn {
        position: absolute;
        z-index: 1;
        bottom: 10px;
        right: 10px;
      }
    }

    .details {
      button {
        padding: 0.3rem 1rem;
      }
    }
  }
}

.tabs-bar {
  position: sticky;
  top: 4.7rem;
  z-index: 98;
  background-color: #fff;

  ul {
    list-style-type: none;

    li {
      &+* {
        margin-left: 1.5rem;
      }

      padding: 1.25rem 0;

      &.active {
        border-bottom: 2px solid $dark;

        a {
          color: $dark !important;
        }
      }

      &:hover {
        a {
          color: $dark !important;
        }
      }
    }
  }
}

// spacing utility classes
.section-spacing {
  margin-bottom: 10rem;
}

.new-notification {
  width: 16px;
  height: 16px;
  background-color: $secondary;
  border: 1px solid #144499;
  border-radius: 50%;
  font-size: 8px;
  font-weight: 600;
  text-decoration: none;
  color: #fff;
}

.auth-input {
  border: 1px solid rgba(0, 0, 0, 0.6);
  border-radius: 5px;
  padding: 0 1.5rem;
  width: 100%;
  margin: 0.7rem;
  display: flex;
  align-items: center;
  justify-content: flex-start;

  span {
    margin: 0 0.5rem;
  }

  input {
    border: none;
    height: 40px;
    line-height: 2em;
    flex-grow: 1;
    width: 100%;

    &:focus,
    &:hover {
      outline: none;
      border: none;
    }

    &::placeholder {
      opacity: 0.9;
    }

    @media ($smallScreen) {
      padding: 1rem 0;
    }
  }

  &.password-field {
    input {
      flex-grow: 1;
    }
  }

  @media ($smallScreen) {
    padding: 0 1rem;
  }
}

.cursor-pointer {
  cursor: pointer;
}

.multimedia-wrapper {
  display: block;
  padding: 3rem;
  text-align: center;
  border: 0.5px solid #cacccf;
  border-radius: 5px;

  label.multiple-media {
    margin-bottom: 3rem;
  }
}