#contest-page {
  background-color: #f9f9fa;


  .search-wrapper {
    position: relative;

    input[type="search"] {
      width: 100%;
      padding: 0.7rem 1.5rem;
      border: none;
      outline: none;
      border-radius: 10px;
      background-color: #f4f7f8;

      :focus {
        outline: none;
        border: none;
        box-shadow: none;
      }
    }

    .search-logo {
      position: absolute;
      right: 1rem;
      top: 0.6rem;
    }
  }

  .show-leaderboard {
    display: none;
  }

  .leaderboard-wrapper {
    max-height: 84vh;
    background-color: #E5F4F2;
    overflow: hidden;
    position: sticky;
    top: 5rem;

    .leaderboard-card-wrapper {
      overflow-y: scroll;

      >*+* {
        margin-top: 1rem;
      }
    }
  }

  .leaderboard-wrapper,
  .leaderboard-modal-wrapper {
    .leader {
      position: relative;
      padding-top: 2rem;

      &.orange {
        &::before {
          background-color: #FF9F00;
        }

        .leader-image {
          border-color: #FF9F00;
        }

        .leader-position {
          background-color: #FF9F00;
        }
      }

      &.blue {
        &::before {
          background-color: #3DC6FC;
        }

        .leader-image {
          border-color: #3DC6FC;
        }

        .leader-position {
          background-color: #3DC6FC;
        }
      }

      &.green {
        &::before {
          background-color: #4CD6B6;
        }

        .leader-image {
          border-color: #4CD6B6;
        }

        .leader-position {
          background-color: #4CD6B6;
        }
      }

      &.has-crown {
        margin-top: -3rem;

        &::before {
          position: absolute;
          content: "";
          background: url(/assets/images/crown.svg) no-repeat center center / contain;
          height: 36px;
          width: 36px;
          top: -10px;
          left: 28%;
        }
      }

      .leader-image {
        overflow: hidden;
        height: 72px;
        width: 72px;
        border-radius: 50%;
        border: 4px solid;

        img {
          width: 100%;
          height: 100%;
          object-fit: cover;
        }
      }

      .leader-position {
        border: 2px solid #fff;
        text-align: center;
        position: absolute;
        bottom: -12px;
        left: calc(68% - 24px);
        width: 28px;
        height: 28px;
        font-weight: 500;
        border-radius: 50%;
        font-size: 0.9rem;
        line-height: 24px;
      }
    }

    @extend %scrollbar-styles;
  }
}

@media ($smallScreen) {
  #contest-page {
    aside {
      position: sticky;
      bottom: 0;
      background: var(--bs-gray-100);
      border-top: 1px solid var(--bs-gray-300);
      padding-bottom: 1rem;
      border-radius: 10px 10px 0 0;
    }

    .vote-button-area {
      // display: none;
    }

    .leaderboard-wrapper {
      display: none;
    }

    .show-leaderboard {
      display: block;
    }
  }
}