@import "./variables";

main.space {
  background-color: #f9f9fa;

  .details {
    .name {

      &:focus-visible,
      &.edit-active {
        outline: none;
        border-bottom: 2px dotted #222 !important;
      }
    }
  }

  .image-loading {
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
    background: rgba(0, 0, 0, 0.4);
    color: #fff;
    font-size: 0.8rem;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  section:not(.jumbotron) {
    // padding: 3rem 0 0;
  }

  %background {
    background-color: #fff;
    border: 1px solid #e5e5e5;
    border-radius: 10px;
  }

  .do-more {
    .wrapper {
      @extend %background;

      p {
        color: #52575c;
      }

      button {
        border-radius: 5px;
      }

      img {
        object-fit: cover;
      }
    }
  }

  .participating {
    .wrapper {
      // @extend %background;
    }
  }

  .voted {
    .wrapper {
      // @extend %background;

      .heading {
        .filter {

          &:hover,
          &focus {
            color: #52575c;
            outline: none;
            box-shadow: none;
          }
        }
      }
    }
  }
}