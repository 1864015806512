@import url("https://fonts.googleapis.com/css2?family=Noto+Sans:ital,wght@0,400;0,700;1,400;1,700&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Source+Sans+Pro:ital,wght@0,400;0,700;1,400;1,700&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Source+Code+Pro:wght@500&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Cormorant:ital,wght@0,300;0,400;0,600;1,400&display=swap");
@import "./topNav";

* {
  font-family: "Source Sans Pro", sans-serif;
  box-sizing: border-box;
}

body {
  width: 100%;
  overflow-x: hidden;
}

.page {
  width: 100vw;
}

.popover {
  background: transparent;
}

.truncate-text {
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
}

.logo-link {
  color: #212529;

  &:hover {
    color: #212529;
  }
}

.alert-close-btn {
  span {
    display: none;
  }
}

#main-header {
  width: 100%;
  z-index: 99;
  box-shadow: 0 2px 8px 4px rgb(0 0 0 / 20%);

  &:not(.landing-page) {
    position: sticky;
    top: 0;
  }

  &.landing-page {
    .navbar {
      position: absolute;
    }
  }
}

.form-select {
  background-image: url(/assets/images/dropdown-icon.svg);
}

.form-switch {
  .form-check-input {
    background-color: #cacccf;
    border-color: transparent;
    background-image: url(/assets/icons/Toggle-Knob.svg);
    font-size: 1.5rem;
    cursor: pointer;

    &:checked {
      background-color: #27a064;
    }
  }
}

#contest-sidebar {
  position: sticky;
  margin-top: -49vh;
  top: 5rem;
  height: 85vh !important;
  overflow: hidden;
}

.infinite-scroll-component {
  position: relative;

  .no-data {
    text-align: center;
    position: absolute;
    bottom: -1rem;
  }
}

#tab-select {
  border-bottom: 1px solid #e5e5e5;

  .tab {
    margin-bottom: -1.5px;
    position: relative;

    & + * {
      margin-left: 2rem;
    }

    button {
      color: rgba(0, 0, 0, 0.4);
    }

    a {
      font-size: 0.8rem;
      margin-left: 0.4rem;
    }

    &.active {
      button {
        color: #212529;
      }

      &::after {
        background-color: $dark;
      }
    }

    &::after {
      content: "";
      position: absolute;
      bottom: -14px;
      left: 0;
      right: 0;
      height: 2px;
      background-color: transparent;
      border-radius: 10px;
    }
  }
}

#contestant-table-wrapper {
  #contestant-table {
    .table-header {
      color: rgba(0, 0, 0, 0.4);
      font-size: 0.8rem;
      text-transform: uppercase;
    }

    .avatar {
      width: 40px;
      height: 40px;
      border-radius: 50%;
      display: inline-block;
      margin-right: 0.5rem;

      img {
        object-fit: cover;
        width: 100%;
        height: 100%;
      }
    }

    .dropdown-menu {
      z-index: 9;
    }
  }

  @media screen and (max-width: 567px) {
    overflow-x: auto;
  }

  .dropdown {
    &-menu {
      padding: 0;
    }

    &-item {
      padding: 0.45rem 1rem;

      &:active {
        background-color: #e9ecef;
      }
    }
  }
}

.richText-input {
  border: 0.5px solid #cacccf;
  border-radius: 5px;
  background-color: transparent;
  transition: 0.3s all ease-in-out;

  .ql-toolbar.ql-snow {
    border: 0;
    border-bottom: 0.5px solid #cacccf;
  }

  .ql-container.ql-snow {
    border: 0;
  }

  .ql-editor {
    font-size: 1rem;
    min-height: 10rem;
    color: #212529;
  }
}

.bg-platinum {
  background: url(/assets/images/platinum-bg.png),
    linear-gradient(160.19deg, #333333 1.29%, #1a1a1a 79.03%);
  background-repeat: no-repeat;
  background-size: cover;
}
