@import "./mixins";

.footer {
  position: relative;
  background-color: var(--bs-gray-100);

  .clipped {
    clip-path: polygon(50% 0, 100% 35%, 100% 100%, 0 100%, 0 35%);
    background-color: $primary;
    padding: 8rem 3rem 1em;
  }

  .join-us {
    @include joinUs(#fff, $primary);
    z-index: 1;
    left: 16%;
    top: 0;

    p {
      color: #52575c;
    }

    form {
      input {
        border: 1px solid #cacccf;
      }
    }
  }

  .page-links {
    a {
      text-decoration: none;
      color: #fff;
      font-size: 0.875rem;
      line-height: 1.6em;
      font-weight: 300;
      padding: 0 0.625rem;

      +* {
        border-left: 1px solid #30c57b;
      }
    }
  }

  .social-links {
    a {
      padding: 0 0.625rem;
    }
  }
}

@media ($smallScreen) {
  .footer {
    .join-us {
      position: static;
      margin-bottom: 16px;
      width: 90% !important;
    }

    .clipped {
      padding: 3rem 1rem 2rem 1rem;
      clip-path: polygon(50% 0, 100% 35%, 100% 100%, 0 100%, 0 35%);
    }
  }
}