header {
  nav {
    position: absolute;
    z-index: 9;
    width: 100%;
    top: 0;
    left: 0;
    padding-top: 2rem;
  }
}

@media ($smallScreen) {
  #carousel {
    .carousel-wrapper {
      clip-path: polygon(0 0, 100% 0, 100% 85%, 40% 100%, 0 90%);
    }

    .arrow-down {
      transform: translate(50%, 50%);
    }
  }
}

.media-carousel {
  top: 50%;
  bottom: unset;
  padding: 15px;

  &:hover {
    background-color: rgba(0,0,0,0.3)
  }

  .sr-only {
    display: none;
  }

  .carousel-control-next-icon {
    background-image: url('https://api.iconify.design/ic/sharp-navigate-next.svg?color=white');
  }

  .carousel-control-prev-icon {
    background-image: url('https://api.iconify.design/ic/twotone-navigate-next.svg?rotate=180deg&color=white');
  }
}