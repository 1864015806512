.lightbox {
  .modal-content {
    background-color: transparent;
    border: 0;
    height: 700px;

    .lightbox-control {
      position: absolute;
      top: 50%;
      transform: translateY(-50);
      background-color: rgba(255, 255, 255, 0.6);
      border: 0;
      color: $primary;

      svg {
        width: 35px;
        height: 35px;
      }

      &.prev {
        left: 0;
      }

      &.next {
        right: 0;
      }
    }
  }

  @media (min-width: 715px) {
    .modal-content {
      .lightbox-control {
        &.prev {
          left: -6rem;
        }
        &.next {
          right: -6rem;
        }
      }
    }
  }

  @media (min-width: 1200px) {
    &.modal-xl {
      max-width: 1100px;
    }
  }
}
